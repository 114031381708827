export const clerkStyles = {
  signIn: {
    elements: {
      rootBox: "max-w-[90%] flex justify-center m-auto text-primary-foreground",
      headerTitle: "font-bold font-ital",
      headerSubtitle: "hidden",
      formFieldInput: "rounded-sm focus:border-text-primary  focus:!shadow-md",
      formButtonPrimary: "bg-border text-primary-foreground rounded-sm hover:bg-border/90",
      footerActionLink: "text-[#000000A6] hover:text-[#0000008C]",
      formFieldAction: "text-primary hover:text-primary/90",
      card: "shadow-md border border-slate-200 rounded-sm overflow-hidden",
      otpCodeField: "text-primary",
      otpCodeFieldInput:
        "text-[#000000A6] border border-text-primary focus:border-primary active:border-primary",
      formResendCodeLink: "text-primary",
      identityPreviewEditButton: "text-primary",
      // scrollBox: "shadow-sm",
      // pageScrollBox: "p-0 border border-white shadow-sm",
      // page: "shadow-none border-white",
    },
  },
  signUp: {
    elements: {
      rootBox: "max-w-[90%] flex justify-center m-auto",
      headerTitle: "font-bold font-ital",
      headerSubtitle: "hidden",
      formFieldInput: "rounded-sm focus:border-text-primary  focus:!shadow-md",
      formButtonPrimary: "bg-border text-primary-foreground rounded-sm hover:bg-border/90",
      footerActionLink: "text-[#000000A6] hover:text-[#0000008C]",
      card: "shadow-md border border-slate-200 rounded-sm overflow-hidden",
      otpCodeField: "text-primary",
      otpCodeFieldInput:
        "text-[#000000A6] border border-text-primary focus:border-primary active:border-primary",
      formResendCodeLink: "text-primary",
      identityPreviewEditButton: "text-primary",
      scrollBox: "shadow-sm",
      pageScrollBox: "p-0 border border-white shadow-sm",
      navbar: "hidden",
      page: "shadow-none border-white",
    },
  },
  userProfile: {
    elements: {
      page: "pt-0 pb-20 px-2 mt-0 max-w-screen-md text-primary-foreground bg-primary/20",
      scrollBox: "text-primary-foreground",
      pageScrollBox: "pt-0 px-0 pb-30 md:pt-2 md:pb-8 md:px-0 text-primary-foreground",
      card: "border-none shadow-none px-2 overflow-hidden text-xs py-0 bg-primary text-primary-foreground",
      navbar: "hidden",
      navbarMobileMenuButton: "hidden",
      formButtonPrimary: "bg-primary rounded-sm hover:bg-primary/90 border text-primary-foreground",
      formButtonReset: "bg-primary/30 text-secondary rounded-sm hover:bg-primary/60 text-primary-foreground",
      formFieldInput: "text-primary-foreground !accent-slate-200 border border-text-primary",
      headerTitle: "text-sm font-bold italic text-primary-foreground",
      headerSubtitle: "text-xs text-primary-foreground",
      profileSectionTitleText: "text-sm text-primary-foreground",
      profileSectionPrimaryButton: "!text-primary-foreground hover:text-primary/90 hover:bg-primary/10",
      profileSectionContent: "!text-primary-foreground",
      profileSectionContent__danger: "!text-primary-foreground",
      accordionTriggerButton:
        "!text-xs py-0 px-4 focus:border-text-primary focus:!shadow-none !text-primary-foreground",
      accordionContent: "!text-primary-foreground",
      activeDevice: "text-xs !text-primary-foreground",
      activeDeviceListItem: "!text-primary-foreground",
      activeDeviceIcon: "w-12 h-12",
      badge: "text-primary-foreground bg-primary/10",
      avatarImageActionsUpload: "text-primary-foreground hover:text-primary-foreground/90"
    },
  },
};
